import React, { useState } from "react";
import styled from "styled-components";
import { DivFlexCen } from "../../../components/utility/flex";
import { BORDER, MARUTSUKE_CORPORATE_WEBSITE_URL, ORANGE_SECONDARY, TEXT_PRIMARY, TEXT_SECONDARY, WHITE } from "../../../config/Constants";
import keisanDojoIndexShowImagePath from '../../../../images/top/keisan_dojo_index_show-min.png';
import keisanDojoDrillResultImagePath from '../../../../images/top/keisan_dojo_drill_result-min.png';
import keisanPrintImagePath from '../../../../images/top/keisan_print-min.png';
import keisanClubSlackImagePath from '../../../../images/top/keisan_club_tensaku.png';
import keisanClubMarutsukeImagePath from '../../../../images/top/keisan_club_marutsuke-min.png';
import keisanClubGraphImagePath from '../../../../images/top/keisan_club_graph-min.png';
import keisanClubPrintImagePath from '../../../../images/top/keisan_club_print-min.png';
import lp01ImagePath from '../../../../images/lp/lp01.webp';
import lp02ImagePath from '../../../../images/lp/lp02.webp';
import lp03ImagePath from '../../../../images/lp/lp03.webp';
import lp04ImagePath from '../../../../images/lp/lp04.webp';
import lp05ImagePath from '../../../../images/lp/lp05.webp';
import lp06ImagePath from '../../../../images/lp/lp06.webp';
import lp07ImagePath from '../../../../images/lp/lp07.webp';
import lp08ImagePath from '../../../../images/lp/lp08.webp';
import lp09ImagePath from '../../../../images/lp/lp09.webp';
import lp10ImagePath from '../../../../images/lp/lp10.webp';
import lp11ImagePath from '../../../../images/lp/lp11.webp';
import lp12ImagePath from '../../../../images/lp/lp12.webp';
import lp13ImagePath from '../../../../images/lp/lp13.webp';
import lp14ImagePath from '../../../../images/lp/lp14.webp';
import lp14Image2Path from '../../../../images/lp/lp14_02.webp';
import lp15ImagePath from '../../../../images/lp/lp15.webp';
import lp15ImageCommentPath from '../../../../images/lp/lp15_comment.webp';
import lp16ImagePath from '../../../../images/lp/lp16.webp';
import lp17ImagePath from '../../../../images/lp/lp17.webp';
import lp18ImagePath from '../../../../images/lp/lp18.webp';
import lp19ImagePath from '../../../../images/lp/lp19.webp';
import lp20ImagePath from '../../../../images/lp/lp20.webp';
import lp20Image2Path from '../../../../images/lp/lp20-2.webp';
import lp21ImagePath from '../../../../images/lp/lp21.webp';
import lp22ImagePath from '../../../../images/lp/lp22.webp';
import lp23ImagePath from '../../../../images/lp/lp23.webp';
import lp24ImagePath from '../../../../images/lp/lp24.webp';
import lp25ImagePath from '../../../../images/lp/lp25.webp';
import lp26ImagePath from '../../../../images/lp/lp26.webp';
import lp27ImagePath from '../../../../images/lp/lp27.webp';
import lp28ImagePath from '../../../../images/lp/lp28.webp';
import marutsukeGifPath from '../../../../images/lp/marutuke.gif';
import ctaButtonPath from '../../../../images/lp/cta_btn.webp';

import { PTextPrimaryCenM, PTextPrimaryCenS, PTextPrimaryS } from "../../../components/utility/textPrimary";
import { ALink } from "../../../components/utility/link";
import { AccordionItem } from "../../../components/atoms/accordionItem";
import { Faq } from "../../../types/faq";
import { APrimaryOrangeButton } from "../../../components/utility/buttons";

type Props = {
  drillSemiKeisanClubNotionUrl: string;
  drillSemiNotionUrl: string;
  faqs: Faq[];
  isLogin: boolean;
}

export const Top:FC<Props>= (props: Props) => {
  const { drillSemiKeisanClubNotionUrl, drillSemiNotionUrl, faqs, isLogin } = props;
  const [displayMorePrintTitle, setDisplayMorePrintTitle] = useState<boolean>(false)
  const [displayMoreVoice, setDisplayMoreVoice] = useState<boolean>(false)
  const [openFaqIds, setOpenFaqIds] = useState<number[]>([]);

  const toggleOpenFaqIds = (faqId: number) => {
    if(openFaqIds.includes(faqId)) {
      const newOpenFaqIds = openFaqIds.filter((id) => faqId !== id)
      setOpenFaqIds(newOpenFaqIds)
    } else {
      const newOpenFaqIds = [...openFaqIds, faqId]
      setOpenFaqIds(newOpenFaqIds)
    }
  }

  const menuButtonToUser = ()  => {
    return(
      <>
        {
          isLogin
          && <DivFlexCen style={{padding: '1rem', backgroundColor: WHITE, color: WHITE}}>
            <PTextPrimaryCenM>
              会員の方はこちら↓
              <APrimaryOrangeButton href={'/menu/study'}>メニュー</APrimaryOrangeButton>
            </PTextPrimaryCenM>
          </DivFlexCen>
        }
      </>
    )
  }

  return(
    <DivLpWrap>
      {
        menuButtonToUser()
      }
      <ImgMarginMinus0p5 src={lp01ImagePath}/>
      <ImgMarginMinus0p5 src={lp02ImagePath}/>
      <ImgMarginMinus0p5 src={lp03ImagePath}/>
      <ImgMarginMinus0p5 src={lp04ImagePath}/>
      <ImgMarginMinus0p5 src={lp05ImagePath}/>
      <ImgMarginMinus0p5 src={lp06ImagePath}/>
      <ImgMarginMinus0p5 src={lp07ImagePath}/>
      <ImgMarginMinus0p5 src={lp08ImagePath}/>
      <ImgMarginMinus0p5 src={lp09ImagePath}/>
      <ImgMarginMinus0p5 src={lp10ImagePath}/>
      <ImgMarginMinus0p5 src={lp11ImagePath}/>
      <ImgMarginMinus0p5 src={lp12ImagePath}/>
      <ImgMarginMinus0p5 src={lp13ImagePath}/>
      <DivRelativeWrap>
        {
          displayMorePrintTitle
          ? <ImgMarginMinus0p5 src={lp14Image2Path}/>
          : <ImgMarginMinus0p5 src={lp14ImagePath}/>
        }
        {
          !displayMorePrintTitle
          && <DivMorePrintButton onClick={()=>setDisplayMorePrintTitle(true)}>
            <span>教材をもっとみる</span>
            <i className="fas fa-chevron-down" style={{fontSize: '1rem', marginLeft: '0.5rem'}}/>
          </DivMorePrintButton>
        }
      </DivRelativeWrap>
      <DivRelativeWrap>
        <ImgMarginMinus0p5 src={lp15ImagePath}/>
        <ImgComment
          src={lp15ImageCommentPath}
        />
      </DivRelativeWrap>
      <ImgMarginMinus0p5 src={lp16ImagePath}/>
      <ImgMarginMinus0p5 src={lp17ImagePath}/>
      <ImgMarginMinus0p5 src={lp18ImagePath}/>
      <ImgMarginMinus0p5 src={lp19ImagePath}/>
      <DivRelativeWrap>
        <ImgMarginMinus0p5 src={lp20ImagePath}/>
        {
          displayMoreVoice
            ? <ImgMarginMinus0p5 src={lp20Image2Path}/>
            : <DivMorePrintButton onClick={()=>setDisplayMoreVoice(true)}>
              <span>もっとみる</span>
              <i className="fas fa-chevron-down" style={{fontSize: '1rem', marginLeft: '0.5rem'}}/>
            </DivMorePrintButton>
        }
      </DivRelativeWrap>
      <ImgMarginMinus0p5 src={lp21ImagePath}/>
      <ImgMarginMinus0p5 src={lp22ImagePath}/>
      <DivRelativeWrap>
        <ImgMarginMinus0p5 src={lp23ImagePath}/>
        <ImgMarutsukeGif src={marutsukeGifPath} />
      </DivRelativeWrap>
      <ImgMarginMinus0p5 src={lp24ImagePath}/>
      <ImgMarginMinus0p5 src={lp25ImagePath}/>
      <ImgMarginMinus0p5 src={lp26ImagePath}/>
      <DivRelativeWrap>
        <ImgMarginMinus0p5 src={lp27ImagePath}/>
        <a href="/school_applications/new" target="_blank">
          <ImgCtaButton src={ctaButtonPath}/>
        </a>
      </DivRelativeWrap>
      <DivFaqWrap>
        <DivFaqTitle>
          よくあるご質問
        </DivFaqTitle>
        {
          faqs.map((faq, index) => (
            <div key={index} style={{marginBottom: '1rem'}}>
              <AccordionItem
                headerTitle={faq.title}
                itemId={`faq-${faq.id}`}
                isOpen={openFaqIds.includes(faq.id)}
                toggleAction={()=>toggleOpenFaqIds(faq.id)}
              >
                <div dangerouslySetInnerHTML={{__html: faq.content}}></div>
              </AccordionItem>
            </div>
          ))
        }
      </DivFaqWrap>
      <DivRelativeWrap>
        <ImgMarginMinus0p5 src={lp28ImagePath}/>
        <a href="/school_applications/new" target="_blank">
          <ImgCtaButton src={ctaButtonPath}/>
        </a>
      </DivRelativeWrap>
      {
        menuButtonToUser()
      }

      <div style={{padding: '1rem 0 3rem 1rem', backgroundColor: WHITE}}>
        <DivLinkWrap>
          <ALinkNavy href={'/'}>
            特定商取引法に基づく表記
          </ALinkNavy>
        </DivLinkWrap>
        <DivLinkWrap>
          <ALinkNavy href={'/'}>
            プライバシーポリシー
          </ALinkNavy>
        </DivLinkWrap>
        <DivLinkWrap>
          <ALinkNavy href={MARUTSUKE_CORPORATE_WEBSITE_URL} target="_blank">
            会社概要
            <i className="fas fa-external-link-alt" style={{marginLeft: '0.25rem'}}/>
          </ALinkNavy>
        </DivLinkWrap>
      </div>
    </DivLpWrap>
  )
}

const DivLinkWrap = styled.div`
  margin-bottom: 1rem;
`;

const ALinkNavy = styled(ALink)`
  color: #003458;
  text-decoration: underline;
`;

const ImgCtaButton = styled.img`
  position: absolute;
  bottom: 3rem;
  width: 90%;
  left: 5%;
`

const DivFaqWrap = styled.div`
  padding: 3rem 2rem;
`;

const DivFaqTitle = styled.div`
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  color: #003458;
  margin-bottom: 2rem;
`;

const DivLpWrap = styled.div`
  background-color: #f0f0f0;
`;

const ImgMarginMinus0p5 = styled.img`
  margin-bottom: -0.5rem;
`;

const ImgComment = styled.img`
  position: absolute;
  width: calc(100% - 5rem);
  left: 2.5rem;
  bottom: 1rem;
`;

const ImgMarutsukeGif = styled.img`
  position: absolute;
  bottom: 12rem;
  left: 35%;
  width: 30%;
`;

const DivRelativeWrap = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const DivMorePrintButton = styled.div`
  min-width: 16rem;
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  color: #003458;
  background-color: ${WHITE};
  border: 2px solid #003458;
  border-radius: 1.5rem;
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translate(-50%, -50%);
`;
