import React, { FC } from "react";
import styled from 'styled-components';
import { BORDER_SECONDARY, TEXT_SECONDARY } from "../../config/Constants";
import { PTextPrimaryXL } from "../utility/textPrimary";

type Props = {
  headerTitle: string;
  children: React.ReactNode;
  itemId?: string;
  isOpen: boolean;
  toggleAction: () => void;
};

export const AccordionItem:FC<Props> = (props) => {
  const { isOpen, headerTitle, children, itemId, toggleAction } = props;


  return(
    <DivAccordionItem id={itemId}>
      <DivAccordionHeader onClick={toggleAction}>
        <PTextPrimaryXL>
          {headerTitle}
        </PTextPrimaryXL>
        <IchevronCircleUp className={"fas fa-chevron-circle-down"} style={{transform: `${ isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}`}}/>
      </DivAccordionHeader>
      <DivAccordionContent isOpen={isOpen}>
        {children}
      </DivAccordionContent>
    </DivAccordionItem>
)
}

const IchevronCircleUp = styled.i`
  margin-left: auto;
  transition: transform 0.3s ease;
  color: ${TEXT_SECONDARY};
`;


const DivAccordionItem = styled.div`
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem;
  text-decoration: none;
  display: block;
  border-bottom: solid ${BORDER_SECONDARY} 1px;
  background-color: #fff;
`;

const DivAccordionHeader = styled.div`
  padding: 0.625rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DivAccordionContent = styled.div`
  overflow: hidden;
  transition: max-height 0.1s ease, padding-top 0.3s ease, padding-bottom 0.3s ease;
  max-height: ${({ isOpen }) => (isOpen ? '100000px' : '0')};
  padding-top: ${({ isOpen }) => (isOpen ? '1rem' : '0')};
  padding-bottom: ${({ isOpen }) => (isOpen ? '1rem' : '0')};
  padding-left: 1rem;
  padding-right: 1rem;
`;
